@media (max-width: 768px){
  .contact-wrap {
    padding-top: 80px;
    h2 {
      margin-bottom: 80px;
    }
    h3{
      font-size: 42px;
    }
  }
}
@media (max-width: 520px){
  .contact-wrap {
    font-size: 22px;
    padding-top: 60px;
    h2 {
      margin-bottom: 60px;
    }
    h3 {
      font-size: 40px;
    }
    .map{
      margin: 0 36px 32px;
    }
  }
}
@media (max-width: 390px){
  .contact-wrap {
    font-size: 20px;
    padding: 40px 20px 0;
    h2 {
      margin-bottom: 40px;
    }
    h3 {
      font-size: 34px;
    }
    .map{
      margin: 0 18px 32px;
    }
  }
}