.head {
  a {
    color: white;
    &:hover {
      color: #0076a3;
      text-decoration: none;
    }

  }
}
.foot {
  color: white;
}
.phones {
  p {
    color: #ffffff;
  }
}
h1 {
  color: #fffefe;
}
.production-wrap {
  .description {
    color: #656565;
  }
  h1 {
    color: #131313;
  }
  .product {
    p {
      color: #0076a3;
    }
  }
}
.news-wrap {
  h3 {
    color: #2c2c2c;
    span {
      color: #0076a3;
    }
  }
  .number {
    color: #3c3c3c;
  }
  .month {
    color: #ffffff;
  }
  article {
    p {
      color: #3c3c3c;
    }
  }
}
.more {
  a {
    color: #2269a8;
  }
}
footer {
  color: #ffffff;
  h4 {
    color: #2a98b1;
  }
}
.about-wrap {
  color: #343434;
  .principles {
    ul {
      li {
        h4 {
          color: #0076a3;
        }
      }
    }
  }
  .button-about {
    a {
      color: #0076a3;
    }
  }
}
.production-wrap {
  a {
    color: #343434;
  }
}

.contact-wrap {
  color: #353535;
  .contacts {
    h3 {
      color: #0076a3;
    }
  }
}
.product-list {
  h1 {
    color: #0076a3;
  }
  .product-title{
    ul{
      li{
        a{
          color: #343434;
        }
      }
    }
  }
}
.news {
  color: #3b3b3b;
  .date-news {
    background-color: #0076a3;
    p {
      color: #ffffff;
    }
  }
  h1 {
    color: #2c2c2c;
  }
  span {
    color: #2c889d;
  }
}
.price {
  color: #353535;
  span {
    color: #0076a3;
  }
}
.singl-nav {
  a {
    color: #2c889d;
  }
}
.category-wrap{
  h1 {
    color: #343434;
  }
  .more-category{
    color: #0076a3;
  }
}





