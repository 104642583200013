.contact-wrap {
  padding: 100px 0 66px;
  text-align: center;
  h2 {
    border-bottom: 6px solid #0076a3;
    display: inline-block;
    margin-bottom: 118px;
    line-height: 52px;
  }
  .map {
    border: 3px #0076a3 solid;
    margin: 0 60px 32px;
    overflow:hidden;
    padding-bottom:42.4%;
    position:relative;
    height:0;
    iframe {
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
    }
  }
  .contacts {
    .contact-name {
      padding: 42px 0 40px 0;
      h4 {
        display: inline-block;
      }
      p {
        display: inline-block;
      }
    }
    .contact-phones {
      ul {
        line-height: 35px;
        padding-bottom: 40px;
      }
    }
    .e-adress{
      span{
        padding-right: 10px;
      }
    }
  }
}