header {
  .head {
    position: relative;
    padding: 26px 50px 14px 74px;
    @include clearfix;
    background-color: #353535;
    //border-bottom: 8px solid #0076a3;

    .logo {
      float: left;
      a {
        display: inline-block;
        img {
          max-width: 100%;
        }
      }

    }
    .header-menu {
      @include clearfix;
      float: right;
      nav {
        padding: 5px 58px 0 0;
        float: left;
        .nav-head {
          li {
            display: inline-block;
            padding-right: 12px;
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
      .icons-header {
        float: right;
        @include clearfix;
        .ytube {
          float: left;
          a {
            display: block;
            width: 36px;
            height: 34px;
            background: url("../img/ytube.png") no-repeat center;
            background-size: cover;
          }
        }
        .language {
          margin: 6px 13px 0 18px;
          float: right;
          li {
            display: inline-block;
            padding-right: 13px;
            &:last-child {
              padding-right: 0;
            }
            a {
              display: block;
              width: 32px;
              height: 27px;

            }
            .ukr {

              background: url("../img/ukr.png") no-repeat center;
              background-size: cover;

            }
            .eng {
              background: url("../img/uk.png") no-repeat center;
              background-size: cover;
            }
            .pl {
              background: url("../img/pl.png") no-repeat center;
              background-size: cover;
            }
            .ru {
              background: url("../img/rus.png") no-repeat center;
              background-size: cover;
            }
          }
        }

      }
    }

    .button-nav {
      float: right;
      display: none;
      position: absolute;
      top: 50px;
      right: 30px;
      background-color: inherit;
      .icon-bar {
        display: block;
        width: 30px;
        height: 2px;
        margin: 7px 0;
        background-color: white;
      }

    }

    .humburger {
      display: block;
      ul {
        margin: 0;
        list-style-type: none;
        li {
          display: inline-block;
          a {
            font-size: 20px;

          }
        }
      }

    }

    div.humburger.show-hide {
      display: block;
      ul{
        position: absolute;
        top:140px;
        right: 5px;
        z-index: 100;

      }
      li {
        display: block;
        margin-bottom: 10px;
        text-align: right;
        margin-right: 0;
        padding-right: 0;
        a{

          font-size: 25px;
        }


      }
    }
  }
}

