@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/helvetica-normal.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoCondensed';
  src: url('../fonts/RobotoCondensed-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoCondensed-Regular';
  src: url('../fonts/RobotoCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'flexslider-icon';
  src:url('../fonts/flexslider-icon.eot');
  src:url('../fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'),
  url('../fonts/flexslider-icon.woff') format('woff'),
  url('../fonts/flexslider-icon.ttf') format('truetype'),
  url('../fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}


.wrapper{
  font-family: 'RobotoCondensed-Regular', sans-serif;
}

.head {
  .header-menu {
    li {
      a {
        font-family: 'Helvetica', sans-serif ;
        font-size: 20px;
        font-weight: 400;

      }
    }
  }
}

.phones{
  p{
    font-family: 'RobotoCondensed', sans-serif;
    font-size: 28px;
    font-weight: 700;
  }
}
h1{
  font-family: 'RobotoCondensed', sans-serif;
  font-size: 104px;
  font-weight: 700;
}
.production-wrap{
  text-align: center;
  .description{
    font-size: 29px;
    font-weight: 400;
    text-align: justify;
  }
  h2{
    font-size: 57px;
    font-weight: 400;
  }
  .product{
    p{
      font-size: 38px;
      font-weight: 400;
    }
  }
}
.news-wrap{
  h3{
    font-size: 55px;
    font-weight: 400;
  }
  .number{
    font-size: 40px;
    font-weight: 400;
  }
  .month{
    font-size: 30px;
    font-weight: 400;
  }
  h4{
    font-family: 'RobotoCondensed', sans-serif;
    font-size: 30px;
    font-weight: 700;
  }
  article{
    p{
      font-size: 20px;
      font-weight: 400;
    }
  }
  .more{
    font-size: 22px;
    font-weight: 400;
  }
}
footer{
  .text-footer{
    font-size: 17px;
    font-weight: 400;
  }
  .contacts-footer{
    .phones{
      li{
        p{
          font-size: 17px;
          font-weight: 400;
          font-family: 'RobotoCondensed-Regular', sans-serif;
        }
      }
    }
    .adress{
      font-size: 17px;
      font-weight: 400;
      h4 {
        font-size: 23px;
      }
    }
  }
}

.about-wrap {
    h2 {
    font-size: 50px;
    font-weight: 400;
  }
  .description-about {
    font-size: 21px;
  }
  h3 {
    font-size: 23px;
    font-weight: 700;
    font-family: 'RobotoCondensed', sans-serif;
  }
  li {
    p {
      font-size: 23px;
    }
  }
  .principles{
    font-size: 21px;
    ul {
      li {
        h4 {
          font-weight: 700;
        }
      }
    }
  }
  .cooperation {
    text-align: center;
    font-size: 20px;
  }
  .button-about{
    font-size: 27px;
    font-weight: 700;
    font-family: 'RobotoCondensed', sans-serif;
  }
}

.contact-wrap{
    font-size: 29px;
    font-weight: 400;
  h1{
    font-size: 50px;
  }
  h3{
    font-size: 46px;
    font-weight: 700;
  }
  .contact-name{
    padding: 0;
  }
  .e-adress{
    span{
      font-weight: 700;
    }
  }
}

.production-wrap {
  .product-title{
    h1{
      font-size: 50px;
    }
  }
  a {
       font-size: 32px;
       font-weight: 400;
       text-align: left;
     }
}

.product-list{
  h1{
    font-size: 60px;
    font-weight: 700;
  }
  a {
    font-size: 32px;
    font-weight: 400;
    text-align: left;
  }
  article {
    h4{
      font-family: 'RobotoCondensed', sans-serif;
    }
    p{
      font-size: 21px;
    }
  }
  .price{
    font-size: 30px;
    span{
      font-family: 'RobotoCondensed', sans-serif;
    }
    p{
      font-size: 24px;
    }
  }
  .button-product{
    p{
      font-family: 'RobotoCondensed', sans-serif;
      font-size: 34px;
    }
  }
}

.news {
  color: #3b3b3b;
  .date-news {
    font-size: 18px;
  }
  ul {
    font-size: 26px;
    li {
      h3 {
        font-size: 18px;
      }
      a {
        color: #353535;
      }
    }
  }
  h1 {
    font-size: 37px;
  }
  p {
    font-size: 19px;
  }
  h4 {
    font-size: 18px;
  }
  article {
    h5 {
      font-family: 'RobotoCondensed-Regular', sans-serif;
      font-weight: 400;
      font-size: 18px;
    }
    ul {
      li {
        .read-more {
          font-size: 19px;
        }
        ul {
          padding-top: 20px;
          li {
            display: block;
            font-size: 19px;
            padding-bottom: 10px;
          }
        }
      }
    }
    .line-productions {
      font-size: 19px;
    }
  }

  .singl-nav{
    font-size: 19px;
  }
}

.category-wrap{
  h1 {
    font-size: 47px;
  }
  ul{
    li{
      font-size: 32px;
    }
  }
  .more-category{
    margin-bottom: 32px;
  }
}

