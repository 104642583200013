@media (max-width: 1480px){}
@media (max-width: 1250px){}
@media (max-width: 768px){
  .news{
    padding: 50px 0 0 0;
    .video{
      margin-bottom: 50px;
      padding: 0 26px;
      li{
        width: 100%;
        &:first-child{
          margin-bottom: 30px;
        }
      }
      .video-container{
        padding-bottom: 56%;
      }
    }
  }
}
@media (max-width: 520px){
  .news{
    padding: 40px 0 0 0;
    h2{
      font-size: 25px;
    }
    .video{
      margin-bottom: 30px;
      padding: 0 10px;
      li{
        &:first-child{
          margin-bottom: 20px;
        }
      }
      .video-container{
        padding-bottom: 56%;
      }
    }
    p{
      font-size: 16px;
    }
  }
}
@media (max-width: 390px){}

