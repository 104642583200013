.wrapper{
  margin: 0 auto;
}
.container{
    max-width: 1364px;
  margin: 0 auto;
}

p,ul,li, h1,h2,h3,h4, ol{
  margin: 0;
  padding: 0;
}

ul{
  list-style-type: none;
}
a{
  text-decoration: none;
  &:active, &:visited{
    color: inherit;
  }
}
svg{width:30%;}