.news {
  padding: 88px 0 0 0;
  .date-news {
    display: inline-block;
    padding: 5px;
    margin-bottom: 28px;
  }
  .bread-crumbs {
    padding-bottom: 2px;
    li {
      display: inline-block;
      a {
        float: left;
        display: block;
        &:after {
          content: "/";
          width: 10px;
          height: 19px;
          display: inline-block;
          padding-left: 10px;
          color: #0076a3;
        }
      }
    }
  }
  h1 {
    padding-bottom: 36px;
  }
  h4 {
    text-align: center;
    padding: 0 0 24px;
  }
  h5{
    margin: 0;
  }
  article {
    padding-bottom: 11px;
    p {
      padding-bottom: 30px;
    }
    .title-description {
      padding-bottom: 30px;
      P{
        padding-bottom: 0;
      }
    }
    .differеnce {
      li {
        padding-bottom: 28px;
        .options {
          padding-bottom: 26px;

        }
        p{
          padding-bottom: 0;
        }
        p.read-more {
          float: none;
          padding-bottom: 28px;
          &:after {
            display: none;
          }
        }
        ul {
          padding: 30px 0 28px 0;
          li {
            display: block;
            padding-bottom: 3px;
          }
        }
      }
    }
  }

  .video {
    margin-bottom: 104px;
    padding: 0 36px;
    @include flexbox;
    li{
      width: 48%;
      &:first-child{
        margin-right: auto;
      }
      &:last-child{
        margin-left: auto;
      }
      iframe{
        width: 100%;
      }
    }
    .video-container {
      position:relative;
      padding-bottom:27%;
      height:0;
      overflow:hidden;
    }
    .video-container iframe, .video-container object, .video-container embed {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }

  ol {
    padding-left: 17px;
    margin-bottom: 24px;
    li {
      padding-bottom: 4px;
    }
  }
  .line-productions {
    li {
      p {
        display: inline-block;
      }
      &:before {
        content: "-";
        text-indent: 5px;
        display: inline-block;
      }
    }
  }

}

.singl-nav{
  padding-top: 34px;
  padding-bottom: 88px;
  a {
    text-transform: uppercase;
  }
  .foll{
    float: right;
    &:after {
      content: "";
      background: url(../img/arrow-right.png) no-repeat center;
      width: 10px;
      height: 13px;
      display: inline-block;
      padding-left: 10px;
      background-size: contain;
    }
  }
  .prev{
    float: left;
    &:before {
      content: "";
      background: url(../img/arrow-left.png) no-repeat center;
      width: 10px;
      height: 13px;
      display: inline-block;
      padding-right: 10px;
      background-size: contain;
      }
    }
}