.category-wrap {
  .category-head {
    text-align: center;
    h1 {
      border-bottom: 6px solid #0076a3;
      display: inline-block;
      margin: 80px 0 68px;
    }
    ul {
      text-align: left;
      padding-bottom: 40px;
      li {
        display: inline-block;
        &:after {
          content: "";
          background: url(../img/rounded-rectangle-3.png) no-repeat center;
          background-size: contain;
          width: 10px;
          height: 19px;
          display: inline-block;
          padding-left: 27px;
        }
      }
    }
  }
  .category-block {
    text-align: center;
    justify-content: space-between;
    padding-bottom: 132px;
    @include flexbox;
    li {
      padding-bottom: 46px;
      width: 27.5%;
      box-sizing: border-box;
      .single-category {
        border: 4px solid #0076a3;
        .img-holder {
          margin-bottom: 22px;
          width: 100%;
          position: relative;
          height: 276px;
          img {
            max-width: 100%;
            height: 100%;
            opacity: 0.01;
            position: relative;
            z-index: 2;
          }
          .img-category {
            //background: url("../img/category-img.png") no-repeat center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background-size: cover;
          }
        }
        h3 {
          padding-bottom: 20px;
        }
        .button-product {
          padding: 0;
          display: inline-block;
          transition: 0.5s;
          margin-bottom: 23px;
          &:hover {
            transform: scale(1.1);
          }
          p {
            cursor: pointer;
            border-radius: 33px;
            padding: 6px 82px;
            transition: 0.3s;
            display: block;
            text-transform: uppercase;
            color: white;
            background-color: #2269a8;
          }
        }
        .more-category {
          margin-bottom: 14px;
          a {
            display: inline-block;
            border: 3px solid #0076a3;
            border-radius: 40px;
            padding: 4px 66px;
            transition: 0.3s;
            text-transform: uppercase;
            margin-bottom: 18px;
            color: #0076a3;
            &:hover {
              background-color: #0076a3;
              color: white;
            }
          }
        }
      }
    }
  }
}