@media (max-width: 1250px) {
  footer {
    .foot {
      padding: 25px 20px;
      .text-footer {
        width: 70%;
        p {
          padding: 0 100px 0 0;
        }
      }
      .contacts-footer {
        width: 30%;
      }
    }
  }
}

@media (max-width: 520px) {
  footer {
    .foot {
      .text-footer {
        width: 100%;
        p {
          padding-right: 0;
          font-size: 16px;
        }
        padding-bottom: 30px;
      }
      .contacts-footer {
        width: 100%;
        text-align: center;
      }
    }
  }

}

@media (max-width: 390px) {
  footer {
    .foot {
      .text-footer {
        p {

          font-size: 13px;
        }
      }
    }
  }
}