@media (max-width: 1460px){
  .description-about{
    padding: 0 25px;
  }
  .about-wrap{
    .list-icons{
      margin:  50px 0 100px;
      padding: 0;
    }
  }
}

@media (max-width: 768px){
  .about-wrap{
    .list-icons{
      li{
        width: 50%;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 520px){
  .about-wrap{
    .list-icons{
      li{
        width: 100%;
      }
    }
    .button-about{
      padding: 7px 20px;
    }
  }
}

