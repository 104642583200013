.banner-wrap {
  background: url("../img/banner.png") no-repeat center;
  background-size: cover;
  .banner-opacity {
    background-color: rgba(59, 116, 161, 0.7);
  }

  .phones {
    float: right;
    padding: 43px 70px 0 0;
    li {
      padding-bottom: 3px;
      &:last-child {
        padding-bottom: 0;
      }
    }

  }
  h1 {
    clear: both;
    text-align: center;
    padding-bottom: 150px;
  }
}

.production-wrap {
  padding: 124px 0 42px;
  .description {

  }
  h2 {
    display: inline-block;
    margin: 45px 0 80px;
    text-align: center;
    border-bottom: 7px solid #0076a3;
  }
  .product {
    @include flexbox;
    li {
      width: 33.3%;
      box-sizing: border-box;
      padding: 0 6px 34px;

      a {
        border: 8px solid #0076a3;
        padding: 0 16px;

        @include flexbox;
        .img-holder {
          width: 34%;
          position: relative;
          height: 161px;
          padding: 6px 0;
          img {
            max-width: 100%;
            height: 100%;
            opacity: 0.01;
            position: relative;
            z-index: 2;

          }
          .img-product {
            //background: url("../img/product1.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

          }
        }
        p {
          box-sizing: border-box;
          margin: auto;
          width: 60%;
          &:after {
            content: '';
            width: 14px;
            height: 25px;
            display: inline-block;
            background: url("../img/arrow.png") no-repeat center;
            background-size: contain;
            margin-left: 20px;
          }
        }

      }
    }
  }
}

.news-wrap {
  padding: 0 0 117px;
  h3 {
    padding-bottom: 92px;

  }
  .owl-carousel.slide-news {
    max-width: 100%;
    position: relative;
    padding: 0 70px;
    box-sizing: border-box;

    .item {
      position: relative;
      .news-head {
        padding-bottom: 26px;
        h4 {
          overflow: hidden;
          max-height: 135px;
        }
        @include clearfix;
        .date {
          padding-right: 7px;
          float: left;
          text-align: center;

          .number {
            padding: 23px 30px;
            background-color: #c8c8c8;
          }
          .month {
            padding: 7px 30px;
            background-color: #737373;
          }
        }
      }
      article {
        padding: 0 0 45px 117px;
        p {
          text-align: justify;
        }
        .more {
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 10;
          .duble-arrow {
            display: inline-block;
            width: 20px;
            height: 12px;
            background: url("../img/double-arrow.png") no-repeat center;
          }
        }
      }
    }

  }

  .owl-nav {
    .owl-prev {
      width: 14px;
      height: 25px;
      background: url("../img/arrow-left.png") no-repeat center;
      background-size: cover;
      color: rgba(0, 0, 0, 0);
      padding: 0;
      margin: 0;
      position: absolute;
      z-index: 20;
      top: 43%;
      left: 0;
      &:hover {
        color: rgba(0, 0, 0, 0);
        background: url("../img/arrow-left.png") no-repeat center;
      }
    }
    .owl-next {
      width: 14px;
      height: 25px;
      background: url("../img/arrow-right.png") no-repeat center;
      background-size: cover;
      color: rgba(0, 0, 0, 0);
      padding: 0;
      margin: 0;
      position: absolute;
      z-index: 20;
      top: 43%;
      right: 0;
      &:hover {
        color: rgba(0, 0, 0, 0);
        background: url("../img/arrow-right.png") no-repeat center;
      }
    }
  }
}



