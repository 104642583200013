@media (max-width: 1460px){
  .category-wrap{
    .category-head{
      h2{
        margin: 60px 0 50px;
      }
      ul{
        padding-bottom: 34px;
      }
    }
    .category-block{
      padding-bottom: 112px;
      li{
        width: 28.5%;
        .single-category{
          .button-product{
            p{
              padding: 6px 62px;
              font-size: 20px;
            }
          }
          .more-category{
            a{
              padding: 4px 49px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1250px){
  .category-wrap{

    .category-head{
      h2{
        margin: 50px 0 40px;
      }
      ul{
        padding-bottom: 28px;
      }
    }
    .category-block{
      padding-bottom: 80px;
      li{
        width: 32%;
        h3{
          font-size: 30px;
        }
        }
      }
    }
  }
@media (max-width: 1100px) {
  .category-wrap{
    .category-head{
      h2{
        margin: 40px 0 30px;
        font-size: 40px;
      }
      ul{
        padding-bottom: 28px;
        li{
          font-size: 26px;
          &:after{
            height: 16px;
          }
        }
      }
    }
    .category-block{
      padding-bottom: 80px;
      li{
        width: 40%;
        margin: auto;
        .single-category{
          h3{
            font-size: 28px;
          }
          .button-product{
            p{
              padding: 6px 52px;
              font-size: 24px;
            }
          }
          .more-category{
            a{
              padding: 4px 38px;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px){
  .category-wrap{

    .category-head{
      h2{
        margin: 30px 0 20px;
        font-size: 30px;
      }
      ul{
        padding-bottom: 20px;
      }
    }
    .category-block{
      padding-bottom: 60px;
      li{
        width: 64%;
        .single-category{
          font-size: 26px;
        h3{
          font-size: 26px;
        }
          .button-product{
            p{
              padding: 6px 52px;
              font-size: 20px;
            }
          }
          .more-category{
            a{
              padding: 4px 42px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 520px){
  .category-wrap{
    .category-head{
      h2{
        margin: 20px 0 20px;
        font-size: 28px;
      }
      ul{
        padding-bottom: 16px;
      }
    }
    .category-block{
      padding-bottom: 40px;
      li{
        width: 86%;
        .single-category{
          font-size: 24px;
          h3{
            font-size: 28px;
          }
        }
      }
    }
  }
}
@media (max-width: 390px){
  .category-wrap{

    .category-head{
      h2{
        margin: 20px 0 18px;
        font-size: 26px;
      }
    }
    .category-block{
      padding-bottom: 20px;
      li{
        width:100%;
        .single-category{
          font-size: 22px;
          h3{
            font-size: 26px;
          }
        }
      }
    }
  }
}


