@media (max-width: 1250px) {
  header {
    .head {
      padding: 26px 50px 60px 74px;
      .header-menu {
        a{
          font-size: 18px;
        }
        .icons-header {
          .language {
            margin-top: 0;
            li {
              padding-right: 8px;
              margin-bottom: 10px;

              .ukr, .eng, .pl, .ru {
                background-size: 75%;
              }
            }
          }
        }
      }
      .header-menu {
        .icons-header {
          position: absolute;
          bottom: 0;
          left: 50px;
          .language {
            li {

            }
          }
        }
      }
    }
  }
}

@media (max-width:768px) {
  .wrapper{
    position: relative;
      }

  header {
    .head {
      padding: 30px  50px 60px;
      .button-nav {
        display: block;
      }
      .humburger {
        display: none;
      }
      div.humburger.show-hide ul{
        top:145px;
        background-color: #353535;
        padding: 0 10px;
        li{
          a{
            font-size: 20px;

          }
        }
      }

    }
  }
}

// humburger

@media (max-width:390px) {
  header{
    .head{
      padding-left: 10px;
      .header-menu{
        .icons-header{
          left: 10px;
        }
      }
      .button-nav{
        top:82px;
      }
    }
  }
}