.product-list {
  padding: 73px 0 46px 0;
  .product-content {
    @include flexbox;
    .flexslider {
      margin: 0 0 5px;
    }
    .slider-block {
      width: 38%;

      box-sizing: border-box;
      .product-carousel {
        margin-bottom: 30px;
        #sync1 .item {
          //background: #0c83e7;
          //padding: 80px 0;
          margin: 5px;
          color: #FFF;
          border-radius: 3px;
          text-align: center;
        }
        #sync2 .item {
          //background: #C9C9C9;
          //padding: 10px 0;
          margin: 5px;
          color: #FFF;
          border-radius: 3px;
          text-align: center;
          cursor: pointer;
        }
        #sync2 .item h1 {
          font-size: 18px;
        }
        #sync2 .current .item {
          background: #0c83e7;
        }
        .owl-theme .owl-nav {
          /*default owl-theme theme reset .disabled:hover links */
        }
        .owl-theme .owl-nav [class*='owl-'] {
          -webkit-transition: all .3s ease;
          transition: all .3s ease;
        }
        .owl-theme .owl-nav [class*='owl-'].disabled:hover {
          //background-color: ;
        }
        #sync1.owl-theme {
          position: relative;
        }
        #sync1.owl-theme .owl-next,
        #sync1.owl-theme .owl-prev {
          width: 22px;
          height: 40px;
          margin-top: -20px;
          position: absolute;
          top: 50%;
        }
        #sync1.owl-theme .owl-prev {
          left: 10px;
        }
        #sync1.owl-theme .owl-next {
          right: 10px;
        }

        .owl-theme {
          .owl-nav {
            /*default owl-theme theme reset .disabled:hover links */
            [class*='owl-'] {
              transition: all .3s ease;
              &.disabled:hover {
                background-color: inherit;
              }
            }

          }
        }

        //arrows on first carousel
        #sync1.owl-theme {
          position: relative;
          .owl-next, .owl-prev {
            width: 22px;
            height: 40px;
            margin-top: -20px;
            position: absolute;
            top: 50%;
          }
          .owl-prev {
            left: 10px;
          }
          .owl-next {
            right: 10px;
          }
        }

        .owl-theme .owl-nav [class*=owl-]{
          background: inherit;
        }

      }
      .video-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
      }
      .video-container iframe, .video-container object, .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    article {
      width: 62%;
      box-sizing: border-box;
      padding-left: 55px;
      padding-bottom: 36px;
      p {
        padding-bottom: 30px;
        text-align: justify;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  .product-title {
    ul {
      li {
        display: inline-block;
        &:after {
          content: "";
          background: url(../img/rounded-rectangle-3.png) no-repeat center;
          width: 10px;
          height: 19px;
          display: inline-block;
          padding-left: 27px;
          background-size: contain;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }

      }

    }
  }
  h1 {
    display: block;
    border: none;
    text-align: left;
    margin: 0;
    padding: 30px 0 41px;
  }
  .price {
    text-align: left;
    padding: 28px 0 80px;

  }
  .button-product {
    padding: 48px 0 0 54px;
    display: inline-block;
    transition: 0.5s;
    &:hover{
      transform: scale(1.1);
    }
    p {
      cursor: pointer;
      border: 8px solid #2269a8;
      border-radius: 33px;
      padding: 3px 103px;
      transition: 0.3s;
      display: block;
      text-transform: uppercase;
      color: white;
      background-color: #2269a8;
    }
  }

  .singl-nav {
    padding-bottom: 80px;
    a {
      text-transform: uppercase;
      &:last-child {
        float: right;
        &:after {
          content: "";
          background: url(../img/arrow-right.png) no-repeat center;
          width: 10px;
          height: 19px;
          display: inline-block;
          padding-left: 10px;
          background-size: contain;
        }
      }
      &:first-child {
        float: left;
        &:before {
          content: "";
          background: url(../img/arrow-left.png) no-repeat center;
          width: 10px;
          height: 19px;
          display: inline-block;
          padding-right: 10px;
          background-size: contain;
        }
      }
    }
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(140,140,140, 0.2);
  font-family: 'RobotoCondensed-Regular', sans-serif;
  .modal-form{
    background-color: #FFFFFF;
    margin: auto;
    color: #0076a3;
    .close {
      background: url(../img/Х.png) no-repeat center;
      background-size: contain;
      float: right;
      margin: 10px;
      width: 20px;
      height: 20px;
    }

    form {
      text-align: left;
      padding: 58px 40px 0;
      input {
        display: block;
        background-color: #d6d7d4;
        margin-bottom: 19px;
        border-style: none;
        color: #3b3b3b;
      }
      input[type=submit] {
        margin: 58px auto 14px;
        color: white;
        background-color: #2269a8;
        padding: 16px 166px;
        border-radius: 18px;
        font-size: 28px;

      }
      input[type=text]{
        height: 50px;
        width: 100%;
      }

      label {
        display: block;
        font-size: 24px;
        padding-bottom: 4px;
      }
    }
    p {
      text-align: center;
      color: #5c5c5c;
      font-size: 23px;
      padding-bottom: 34px;
    }
  }


}




