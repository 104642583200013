footer{
  background-color: #353535;
.foot {
  padding: 26px 48px 33px 60px;
  @include flexbox;
  flex-flow: row wrap;
  .text-footer {
    box-sizing: border-box;
    width: 80%;
    p {
      padding: 40px 54% 0 0;
      text-align: justify;
    }
  }
  .contacts-footer {
    width: 20%;
    .adress {
      h4{
        padding-bottom: 10px;
      }
      p {
        padding-bottom: 27px;
      }

    }
    .phones {
      padding-bottom: 27px;
    }

  }
}
}