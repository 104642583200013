@media (max-width: 1460px) {
  .container {
    max-width: 1200px;
  }
    .production-wrap {
      .product {
        li {
          a {
            p {
              font-size: 35px;
              &:after {
                height: 20px;
              }
            }
          }
        }
      }
    }

}

@media (max-width: 1250px) {

  .banner-wrap {

    .phones {
      padding: 43px 70px 0 0;
    }
    h1 {
      font-size: 80px;
      padding-bottom: 100px;
    }
  }
  .production-wrap {
    padding: 90px 0 42px;
    .description {
      font-size: 24px;
      padding: 0 5px;
    }
    .product {
      li {
        width: 50%;
        a {
          border: 3px solid #0076a3;
          .img-holder {
          }
          p {
            font-size: 30px;
            text-align: center;
            &:after {
              height: 17px;
              background-size: contain;
            }
          }
        }

      }
    }
  }
  .news-wrap {
    h3 {
      font-size: 50px;
      padding-bottom: 65px;
    }
    .owl-carousel.slide-news {
      .item {
        .news-head {
          h4 {
            max-height: 100px;
            font-size: 22px;
          }
          .date {
            .number {
              padding: 10px 20px;
              font-size: 30px;
            }
            .month {
              padding: 10px 20px;
              font-size: 25px;
            }
          }
        }
        article {
          padding: 0 0 45px 80px;
          p {
            font-size: 16px;
          }
          .more {
            font-size: 18px;
          }
        }
      }

    }
  }
}

@media (max-width: 768px) {
  .container{
    padding: 0 20px;
  }
  .banner-wrap {
    .phones {
      float: none;
      padding: 30px 20px 20px;
      p {
        font-size: 22px;
      }
    }
    h1 {
      font-size: 50px;
      padding-bottom: 40px;
    }
  }
  .production-wrap {
    padding: 60px 0 42px;
    .description {
      padding: 0 25px;
      font-size: 22px;
    }
    h2 {
      font-size: 45px;
      margin: 40px 0;
    }
    .product {
      li {
        a {
          padding-right: 0;
          p {
            font-size: 23px;

            &:after {
              height: 13px;
              margin-left: 3px;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .banner-wrap {
    h1 {
      font-size: 30px;
    }
    .phones {
      p {
        font-size: 16px;
      }
    }
  }
  .production-wrap {
    .description {
      font-size: 18px;
    }
    h2 {
      font-size: 40px;
    }
    .product {
      li {
        width: 100%;
        a {
          p {
            font-size: 27px;
            &:after {
              height: 17px;
            }
          }

        }
      }
    }
  }
  .news-wrap {
    padding-bottom: 50px;
    .owl-carousel.slide-news {
      .item {
        .news-head {
          h4 {
            max-height: 100px;
            font-size: 22px;
          }
          .date {
            .number {
              padding: 6px 10px;
              font-size: 20px;
            }
            .month {
              padding: 6px 10px;
              font-size: 18px;
            }
          }
        }
        article {
          padding: 0 0 30px;
          p {
            font-size: 16px;
          }
          .more {
            font-size: 18px;
          }
        }
      }

    }
    h3 {
      font-size: 40px;
      padding-bottom: 50px;
    }
  }
}

@media (max-width: 390px) {
  .banner-wrap {
    h1 {
      font-size: 32px;
      padding-bottom: 20px;
    }
  }
  .production-wrap {
    h2 {
      font-size: 28px;
    }
  }
  .news-wrap {
    h3 {
      font-size: 28px;
    }

    .owl-carousel.slide-news {
      padding: 0 10px;
      .item article p {
        height: 144px;
        overflow: hidden;
      }
    }
  }
}
