.products{
  padding: 80px 0 67px 0;
  .product-title {
    h1 {
      border-bottom: 6px solid #0076a3;
      display: inline-block;
      margin: 0 0 68px;
      line-height: 52px;
    }
    a {
      display: block;
      padding-bottom: 42px;
      &:after {
        content: "";
        background: url(../img/rounded-rectangle-3.png) no-repeat center;
        width: 10px;
        height: 19px;
        display: inline-block;
        padding-left: 27px;
      }
    }
  }
}
