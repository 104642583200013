.about-wrap {
  padding: 107px 0 112px;
  text-align: center;
  h2 {
    border-bottom: 6px solid #0076a3;
    display: inline-block;
    margin-bottom: 36px;
    line-height: 52px;
  }
  .description-about{
    text-align: justify;

  }
  .principles{
    text-align: justify;
    padding-bottom: 50px;
    h3 {
      padding-bottom: 26px;
    }
    ul {
      padding-bottom: 4px;
      li {
        p {
          padding-bottom: 20px;
        }
      }
    }
    p {
      padding-bottom: 30px;
    }
    .service {
      p {

      }
      ul {
        li {
          &:before {
            content: '-';
            padding-right: 5px;
          }
        }
      }
    }
  }
  .description-about{
    padding-bottom: 66px;
  }
  .list-icons {
  @include flexbox;
    li {
      width: 25%;
      box-sizing: border-box;
    }
  }
  .list-icons {

    padding: 0  248px 100px;
      li{
        p{
          padding-top: 35px;
        }
      }
  }

  .cooperation {
    padding-bottom: 94px;
  }

  .button-about {
    display: inline-block;
    a {
      border: 8px solid #0076a3;
      border-radius: 15px;
      padding: 7px 46px;
      transition: 0.3s;
      display: block;
text-transform: uppercase;
      &:hover{
        background-color:#0076a3;
        color: white;
      }
    }

  }
}