@media (max-width: 1250px) {

  .product-list {
    padding: 50px 20px;
    .product-content {
      article {
        padding-left: 30px;
      }
    }
  }
}

@media (max-width: 950px) {
  .product-list {
    .price {
      text-align: center;
      padding: 28px 0 60px;
      p {
        font-size: 20px;
      }
    }
    a {
      font-size: 26px;
    }
    h2 {
      font-size: 50px;
    }
    .button-product {
      padding: 48px 0 0 0;
      a {
        font-size: 26px;
      }
    }
    .product-content {
      .slider-block {
        padding: 0 115px 50px;
        width: 100%;
      }
      article {
        padding: 0;
        width: 100%;
      }
    }
    .singl-nav {
      padding-bottom: 60px;
    }
  }
}

@media (max-width: 768px) {
  .product-list {
    .product-title {
      ul {
        li {
          a {
            font-size: 24px;
            &:after {
              height: 16px;
              padding-left: 20px;
            }
          }
        }
      }
    }
    .product-content {
      .slider-block {
        padding: 0 0 50px;
      }
    }
    .singl-nav {
      padding-bottom: 40px;
      a {
        font-size: 22px;
      }
    }
  }

  .modal {
    .modal-form {
      form {
        padding: 42px 30px 0;

        input[type=submit] {
          margin: 50px auto 14px;
          padding: 12px 100px;
          font-size: 22px;
        }

        input[type=text] {
          width: 450px;
          margin: 0 auto 19px;
        }

      }
    }
  }
}

@media (max-width: 520px) {
  .product-list {
    padding: 30px 0 0;

    h2 {
      font-size: 40px;
    }
    .price {
      padding: 28px 0 40px;
    }
    .button-product {
      padding: 40px 0 0 0;
    }
    .product-title {
      ul {
        li {
          a {
            font-size: 20px;
            &:after {
              height: 14px;
              padding-left: 16px;
            }
          }
        }
      }
    }
    article {
      p {
        font-size: 18px;
      }
    }
  }

  .modal {
    .modal-form {
      form {
        padding: 42px 20px 0;
        input[type=submit] {
          margin: 24px auto 14px;
          padding: 12px 66px;
          font-size: 16px;
        }

        input[type=text] {
          width: 340px;
        }
        label{
          font-size: 22px;
        }
      }
      p{
        font-size: 16px;
      }
    }
  }

}

@media (max-width: 390px) {

  .product-list {
    padding: 12px 5px 0;
    h2 {
      font-size: 26px;
      padding: 26px 0;
    }
    .price {
      padding: 28px 0 40px;
      h4 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
    .button-product {
      padding: 30px 0 0 0;
      p {
        font-size: 20px;
        padding: 3px 56px;
      }
    }
    .product-title {
      ul {
        li {
          a {
            font-size: 18px;
            &:after {
              height: 12px;
              padding-left: 14px;
            }
          }
        }
      }
    }
    .product-content {
      .slider-block {
        padding-bottom: 20px;
      }
      article {
        p {
          padding-bottom: 17px;
        }
      }
    }
    .singl-nav {
      padding-bottom: 50px;
      a {

        font-size: 16px;
      }
    }
  }

  .modal {
    .modal-form {
      form {
        padding: 40px 10px 0;
        input[type=submit] {
          margin: 22px auto 14px;
          padding: 10px 45px;
          font-size: 14px;
        }

        input[type=text] {
          width: 280px;
          margin: 0 auto 10px;
        }
        label{
          font-size: 20px;
        }
      }
      p{
        font-size: 14px;
      }

    }
  }
}
