@import 'libs/owl.carousel.min';
@import 'libs/owl.theme.default.min';


@import "normalize/import-now";



@import 'mixins/mixins';

@import 'utils/variables';

@import 'base/typography';

@import 'layout/grid';
@import 'layout/header';
@import 'layout/footer';


@import 'pages/home';
@import 'pages/about';
@import 'pages/contact';
@import 'pages/products';
@import 'pages/single-product';
@import 'pages/news';
@import 'pages/category';

@import 'themes/theme';

@import 'media/header-media';
@import 'media/home-media';
@import 'media/footer-media';
@import 'media/about-media';
@import 'media/contact-media';
@import 'media/single-media';
@import 'media/news-media';
@import 'media/category-media';









